@import "src/constants";

.loginPageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $commonDark;
}

.loginForm {
    margin: 20px;
    max-width: 450px;
    width: 100%;
}
