@import "src/constants";

.container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
}

.actionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #ece9ff !important;
}

.action {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $commonBlack;
    padding: 6.5px 18.5px;
    border-radius: 16px;
    cursor: pointer;
    margin-right: 64px;

    .rightPart {
        color: $commonBlue;
        font: bold 18px/32px "Open Sans", serif;
    }

    .leftPart {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
    }
}

.action:hover {
    .rightPart {
        display: flex;
        font-size: 18px;
    }
    .leftPart {
        margin-right: 8px;
    }
}

.action:last-child {
    margin-right: 0;
}

.actionHelpIcon {
    color: #ece9ff !important;
    cursor: pointer;
}

.radio {
    color: #ece9ff !important;
}

.searchIcon {
    width: 24px;
    height: 24px;
    background: no-repeat url("search.svg");
}

.editIcon {
    width: 24px;
    height: 24px;
    background: no-repeat url("edit.svg");
}

.backlogIcon {
    width: 24px;
    height: 24px;
    background: no-repeat url("backlog.svg");
}

.uploadIcon {
    width: 24px;
    height: 24px;
    background: no-repeat url("upload.svg");
}

.externalIcon {
    width: 24px;
    height: 24px;
    background: no-repeat url("external.svg");
}
