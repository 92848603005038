@import "src/constants";

.container {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: $commonDark;

    .subContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }
}

.paginationBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 150px;
}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-top: 20px;

    .goToPageContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .goToPageButton {
            cursor: pointer;
            margin: 0 5px 0 5px;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid $commonBlue;
            color: $commonBlue;
        }

        .inputField {
            max-width: 80px;
            margin-left: 15px;
        }
    }
}

.pagination {
    display: flex;
    align-items: start;
    justify-content: center;
    list-style: none;
    cursor: pointer;
}

.paginationLink {
    margin: 0 5px 0 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    background-color: $commonDark;
    font-weight: bold;
    cursor: pointer;
}

.paginationLinkActive {
    color: #FFFFFF !important;
    background-color: $commonLightBlue !important;
    border: none !important;
}

.paginationLinkDisabled a {
    color: #000000;
    border: 1px solid #000000;
}

.loaderContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(85, 169, 255, 0.3);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled {
    color: #C6C5CA;
    border: 1px solid #C6C5CA;
    cursor: default;
}