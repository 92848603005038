.pageContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .main {
        flex-grow: 1;
        flex-shrink: 1;
        height: calc(100% - 80px);
    }
}
