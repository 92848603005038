@import "src/constants";

.popupBack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(0, 0, 0, 0.4);

    .popupContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: calc(50% - 250px);
        width: 500px;
        max-width: calc(100% - 20px);
        background-color: white;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        gap: 16px;

        .posTagValue {
            font-family: Open Sans, sans-serif;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0px;
            text-align: center;
            color: #707070;
        }

        .posTagTitle {
            font-family: Open Sans, sans-serif;
            font-size: 15px;
            font-weight: 800;
            letter-spacing: 0px;
            text-align: left;
            color: #707070;
        }

        .posTagText {
            font-family: Open Sans, sans-serif;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0px;
            text-align: left;
            color: #707070;

        }

        .posTagName {
            font-family: Open Sans, sans-serif;
            font-style: italic;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: 0px;
            text-align: left;
            color: #707070;

        }

        .messageContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 10px 15px 10px;
            flex-grow: 1;
            gap: 8px;

            .message {
                font: normal normal 17px/20px "Roboto", sans-serif;
                text-align: center;
                color: #8697a8;
            }
        }

        .errorsContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 15px;
            flex-grow: 1;

            .error {
                font: normal normal 14px/16px "Roboto", sans-serif;
                text-align: left;
                color: #7d2f2f;
            }
        }

        .buttonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
            width: 80%;
            text-transform: none;
            color: black;

            .buttonAction {
                cursor: pointer;
                padding: 0 20px;
                height: 40px;
                background-color: $commonBlue;
                border-radius: 8px;
                border-width: 0;
                border-color: transparent;
                text-align: center;
                line-height: 40px;
                color: white;
            }

            .buttonCancel {
                cursor: pointer;
                width: 105px;
                height: 40px;
                background-color: transparent;
                line-height: 40px;
                border-color: $commonBlue;
                border-width: 1px;
                border-radius: 8px;
                text-align: center;
                color: $commonBlue;
                margin-left: 16px;
            }

            .buttonAction:focus,
            .buttonCancel:focus {
                outline: none;
            }
        }
    }
}
