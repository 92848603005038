@import "src/constants";

.topNavBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: $commonBlack;

    .elem {
        padding: 10px;
    }
}

.linkUsers {
}

.appName {
    color: $commonBlue;
    font-family: "Open Sans",sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    cursor: pointer;
    text-decoration: none;
}