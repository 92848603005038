@import "src/constants";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;

    .editUserContainer {
        display: block;
        position: relative;
        width: 500px;
        height: calc(100% - 160px);
        overflow-y: auto;
        padding: 5px 10px;

        .passwordsContainer {
            display: flex;
            flex-direction: row;

            > div:first-child {
                margin-right: 20px;
            }
        }

        .emailInput {
            display: block;
            margin-bottom: 20px;
        }

        .roleContainer {
            margin: 10px 0 25px 0;
        }
    }

    .buttonsGroup {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .disabled {
        opacity: 0.5;
        filter: grayscale(1);
    }
}

.saveButtonColor {
    background-color: $commonBlue !important;
}
