@import "src/constants";

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $commonDark;
}

.loaderContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(85, 169, 255, 0.3);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
}

.uploadHelpIcon {
    cursor: pointer;
}

.uploadCheckButton {
    position: absolute !important;
    right: 100px;
    bottom: 35px;
    width: 300px;
    color: $commonBlue !important;
    background-color: $commonBlue !important;
    span {
        color: #FFFFFF;
    }
}

.chooseSeparators {
    color: #FFFFFF;
    margin: 10px 5px 10px 5px;
    display: flex;
    flex-direction: row;
    border: 0.5px solid #b1b1b1;
    border-radius: 5px;
    padding: 5px 15px 0 15px;
}

.uploadErrorsContainer {
    max-height: 400px;
    margin-top: 30px;
    background-color: $commonBlack;
    padding: 25px;
    border-radius: 16px;

    .errorsListContainer {
        overflow-y: auto;
        padding: 5px;
        max-height: 300px;
    }
    .uploadTitle {
        display: block;
        font-weight: bold;
        margin-bottom: 10px;
        color: #FFFFFF;
    }

    .uploadSuccessTitle {
        color: green;
    }

    .uploadErrorsTitle {
        color: darkred;
    }

    .uploadErrors {
        display: block;
        list-style: none;
        padding: 0 20px 0 0;
        margin: 0;
        color: #FFFFFF;
        > li {
            margin-bottom: 5px;
        }
    }
}

.backLink {
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    left: 50px;
    width: 250px;
    color: $commonBlue;
    text-decoration: none;
}

.successResult {
    display: block;
    color: green;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.errorResult {
    display: block;
    color: red;
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.disabled {
    opacity: 0.5;
    filter: grayscale(1);
}

.inputRadioCheckbox {
    color: $commonBlue !important;
}

.text {
    color: #FFFFFF;
}