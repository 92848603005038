@import "../../constants";

.loginContainer {
    display: flex;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .loaderContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(85, 169, 255, 0.3);
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > div {
        margin-bottom: $commonPadding;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.input {
    color: red;
}

.errorMessage {
    display: block;
    color: red;
    margin-top: 5px;
}

.saveButtonColor {
    background-color: $commonBlue !important;
}
