@import "src/constants";

.container {
  display: flex;
}

.inputText {
  outline: 0;
  border: 1px solid black;
  border-radius: 4px;
  height: 36px;
  width: 300px;
  background-color: $commonBlack;
  color: #FFFFFF;
  padding-left: 10px;
}

.inputButton {
  border-radius: 3px;
  margin-left: 10px;
  outline: 0;
  border: none;
  padding: 10px 15px;
  background-color: $commonBlue;
  color: #FFFFFF;
  text-transform: uppercase;
  transition: background-color 0.2s;
  cursor: pointer;
  height: 36px;

  &:hover {
    background-color: $commonBlue;
  }
}