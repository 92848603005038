.menuContainer {
  display: flex;
  min-width: 200px;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;

  &Row {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &LeftItem {
      min-width: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-right: 15px;
      font-weight: bold;
      font-size: 14px;
    }

    &RightItem {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    &CloseItem {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-right: 0;
      padding-left: 0;
    }
  }
  &Row:first-child{
    margin-top: 0;
  }
}

.menu {
  cursor: pointer;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.menuIcon {
  color: #FFFFFF;
  width: 32px !important;
  height: 32px !important;
}

.subElem {
  padding-left: 20px;
}

.subElemRightPart {
  margin-right: 0 !important;
}

.separatedElem {
  margin-bottom: 8px;
}