@import "src/constants";

.filterContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2px 0 16px 0;
}

.addButton {
  width: 151px;
  height: 41px !important;
  background-color: $commonBlue !important;
  color: #FFFFFF !important;
  white-space: nowrap;
  font-weight: bold !important;
  font-size: 16px !important;
}

.containerStart {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  .subContainerOther {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;
    padding-top: 10px;
  }
}

.container {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .subContainerOther {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 20px;
    padding-top: 10px;
  }

  .subContainerButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 10px;
  }

  .filterSelect {
    width: 140px !important;
    height: 41px !important;
    border-radius: 5px;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  *::-webkit-scrollbar {
    width: 7px;
    display: block;
    position: absolute;
    left: 0;
    height: 0;
  }

  *::-webkit-scrollbar-track {
    width: 2px;
    background: #a5a5a5;
    border: 1px solid transparent;
    background-clip: content-box;
  }

  *::-webkit-scrollbar-thumb {
    display: block;
    width: 1px !important;
    height: 40px !important;
    background-color: #44566c;
    border-radius: 5px;
  }

  .dateInput {
    width: 141px !important;
    background-color: #081023;
    color: #FFFFFF !important;
    margin: 0 !important;
    border-radius: 5px;
    padding-left: 2.5px !important;
  }

  .subContainerOtherTitle {
    color: #FFFFFF;
    font-size: 13px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    margin-bottom: 5px;
    margin-left: 2px;
  }


  .subContainerDate {
    display: flex;
    align-items: center;
    margin-right: 20px;
    width: 170px;
    border-radius: 5px;
    color: #FFFFFF !important;
  }

  .searchButton {
    width: 76px;
    height: 41px !important;
    background-color: $commonBlue !important;
    color: #FFFFFF;
    border-radius: 8px;
    font-weight: bold !important;
    font-size: 16px !important;
  }

  .subContainer:first-child {
    margin-left: 0;
  }

  .filterList {
    width: 170px;
    height: 30px;
    border-radius: 5px;
  }

  .loadButton {
    background-color: $commonBlue !important;
    color: #FFFFFF;
    text-transform: none;
  }
}

.dateIcon {
  width: 24px;
  height: 24px;
  background: no-repeat url("calendar.svg");
}

.plusIcon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  background: no-repeat url("plus.png");
}
