$commonPadding: 10px;
$commonLightBlue: #03A9F4;
$commonBlue: #0E4DB7;
$commonGrey: #546e7a;
$commonDark: #1E293B;
$commonBlack: #091124;
$commonRed: #E26655;
$commonGreen: #4CAF50;

$notifyWarning: #FFE097;
$notifyError: #E26655;

%detailedNetHeaderEmpty {
  width: 100%;
  font-size: 15px;
  font-family: "Open Sans", "SansSerif", sans-serif;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  font-style: italic;
}

%detailedNetHeader {
  font-size: 15px;
  font-family: "Open Sans", "SansSerif", sans-serif;
  color: $commonLightBlue;
}

%subElemSpanGrey {
  color: #707070 !important;
  margin-left: 10px !important;
  font-size: 12px !important;
}

%subElemNet {
  font-size: 15px;
  color: $commonLightBlue !important;
}

%header {
  font-size: 18px;
  font-family: "Open Sans", "SansSerif", sans-serif;
  font-weight: 700;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 25px;
}

%disabled {
  opacity: 0.5;

}
