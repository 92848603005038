@import "src/constants";

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 0;
  padding: 0;
  overflow-x: hidden;
  font-size: 14px;

  .notifyClose {
    display: none;
  }

  .notifyText {
    color: #FFFFFF;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 260px;
    font-size: 16px;
    font-weight: 400;
  }

  .containerTop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .notifyObject {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    width: 100%;

    &LeftPart {
      width: calc(100% - 70px);
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      .wordPartItem {
        word-wrap: break-word;
        width: 100%;
        margin-right: 8px;
      }
    }

    &RightPart {
      width: 70px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 5px 0 0 0;

      .closeIconContainer {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2E415B;
        color: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    align-items: center;
    color: #FFFFFF;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 32px;
    font-weight: 800;
    max-width: 290px;

    .errorNotifyIcon {
      width: 24px;
      height: 24px;
      margin: 5px 5px 0 0;
    }

  }

  .notifyListContainer {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    list-style: none;

    .notifyListElem {
      background-color: #2E415B;
      padding: 8px;
      color: #FFFFFF;
      margin: 5px 5px 0 0;
      border-radius: 8px;
      height: 32px;
      max-height: 40px;
    }

    .notifyListElem:last-child {
      margin-bottom: 0;
    }
  }
}

.containerActive {
  padding: 30px 0 10px 10px;
  margin-left: 24px;
  position: relative;
  width: 310px;
  transition: width 0.5s cubic-bezier(0.14, 0.58, 0.67, 0.985);
  background-color: $commonBlack;
  border-radius: 16px;

  .notifyClose {
    display: block;
    position: absolute;
    top: 3px;
    right: 5px;
    cursor: pointer;
    transition: color 0.2s linear;
    color: #FFFFFF;
  }

  .notifyClose:hover {
    color: darkred;
  }
}

.closeIcon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: no-repeat url("close.svg");
}