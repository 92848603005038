@import "src/constants";

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: calc(100% - 150px);

  .leftPart {
    width: calc(100% - 400px);
    display: flex;
    flex-direction: column;
    min-width: 650px;
    padding: 0 5px 0 5px;

    .topRow {
      width: 100%;
      border-top-right-radius: 16px !important;
      border-top-left-radius: 16px !important;
      background-color: $commonBlack;
    }

    .bottomRow {
      overflow-y: auto;
      z-index: 5;

      .noResultsFound {
        font-size: 18px;
        font-family: "Open Sans", "SansSerif", sans-serif;
        color: #ffffff;
        font-weight: 700;
        text-align: center;
        padding-top: 10%;
        font-style: italic;
      }
    }

    .bottomRow::-webkit-scrollbar-button {
      display: none;
    }

    .bottomRow::-webkit-scrollbar {
      width: 7px;
      display: block;
      position: absolute;
      left: 0;
      height: 0;
    }

    .bottomRow::-webkit-scrollbar-track {
      width: 2px;
      background: #a5a5a5;
      border: 1px solid transparent;
      background-clip: content-box;
    }

    .bottomRow::-webkit-scrollbar-thumb {
      display: block;
      width: 1px !important;
      height: 40px !important;
      background-color: #44566c;
      border-radius: 5px;
    }

    .row {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      min-height: 40px;
      min-width: 650px;

      .languageColumn {
        min-width: 175px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        color: #ffffff;
        font-size: 18px;
        padding-left: 36px;
        font-family: "Open Sans", "SansSerif", sans-serif;
        font-weight: 700;

        .columnSort {
          padding: 16px 0 12px 0;
          visibility: hidden;
        }
      }

      .languageColumn:hover {
        .columnSort {
          visibility: visible;
        }
      }

      .languageColumnRow {
        display: flex;
        padding-top: 5px;
        padding-left: 36px;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        min-width: 175px;
        color: #ffffff;
      }

      .columnRow {
        padding-left: 36px;
        padding-top: 16px;
        padding-bottom: 12px;
        color: #ffffff;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        cursor: pointer;

        &Elem {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          color: #ffffff;
          height: 100%;
        }

        .subElem {
          font-size: 14px;
          margin: 5px 0 5px 10px !important;
        }
      }

      .column {
        cursor: pointer !important;
        color: #ffffff;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 36px;
        align-items: center;
        font-size: 18px;
        font-family: "Open Sans", "SansSerif", sans-serif;
        font-weight: 700;

        .columnSort {
          margin-top: 12px;
          visibility: hidden;
        }
      }

      .column:hover {
        .columnSort {
          margin-top: 12px;
          visibility: visible;
        }
      }

      .lemmaColumn {
        width: calc(100% - 23.993px) !important
      }
    }
  }

  .rightPart {
    width: 400px;
    min-width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: $commonDark;
    padding: 0 5px 0 5px;

    .topPart {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font: bold 18px/32px "Open Sans", serif;
      background-color: $commonBlack;
      min-height: 41.545px;
      max-height: 41.545px;
      color: #ffffff;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      padding: 0;
    }
  }
}

.firstRow {
  background-color: $commonBlack !important;
}

.secondRow {
  background-color: $commonDark !important;
}

.subElemSpan {
  margin-left: 10px !important;
  font-size: 15px !important;
  font-style: italic;
  font-family: "Open Sans", "SansSerif";
  color: #707070;
}

.subElemSpanGrey {
  color: #707070 !important;
  margin-left: 10px !important;
  font-size: 12px !important;
}

.subElemNet {
  margin-bottom: 10px;
  font-size: 15px;
  color: $commonLightBlue;
}

.labelInput {
  width: auto;
  font-size: 15px;
  font-family: "Open Sans", "SansSerif", sans-serif;
  color: $commonLightBlue;
  border: none;
  background: transparent;
  text-decoration: none;
  box-shadow: none;
  user-select: none;
  outline: none;
}

.highLight {
  color: $commonLightBlue !important;
}

.header {
  @extend %header;
  margin: 0;
  min-height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

.subElemSpanGrey {
  @extend %subElemSpanGrey;
}

.subElemNet {
  @extend %subElemNet;
}

.detailedNetHeader {
  @extend %detailedNetHeader;
}

.detailedNetHeaderEmpty {
  @extend %detailedNetHeaderEmpty;
}

.editPencilIcon {
  width: 24px;
  height: 24px;
  background: no-repeat url("pencil-02.svg");
  margin: 0 4px 4px 4px;
}
