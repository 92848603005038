@import "src/constants";

.loader {
    position: absolute;
    left: 40px;
    top: -21px;
    z-index: 0;
}

.spelling {
    display: block;
    width: 100%;
    position: relative;

    .spellingBorder {
        border: solid 1px !important;
    }

    .spellingAddIcon {
        display: block;
        position: absolute;
        cursor: pointer;
        font-size: 22px;
        color: green;
        top: -23px;
        left: 0px;
    }

    .spellingRow {
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        .spellingRemoveIcon {
            cursor: pointer;
            font-size: 22px;
            color: darkred;
            margin-left: 0;
        }

        .spellingIconDisable {
            color: hsl(0, 0%, 90%);
            cursor: not-allowed;
        }

        .spellingInput {
            width: 100%;
            min-height: 37.99px;
            padding: 2px 10px;
            border-radius: 5px;
            color: #FFF !important;
        }

        .spellingInputBlack {
            color: #000 !important;
        }
    }

    .spellingRow:first-child {
        padding-top: 0;
    }

    .spellingRow:last-child {
        padding-bottom: 0;
    }
}

.spellingDisabled{
    opacity: 0.5;
}

.disabled {
    opacity: 0.5 !important;
}

.iconContainerPlus {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $commonGreen;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 5px;
}


.plusIcon {
    cursor: pointer;
    color: #FFFFFF !important;
    width: 16px !important;
    height: 16px !important;
    background: no-repeat url("plus.png");
}

.iconContainerCross {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $commonRed;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 5px;
}
.iconsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: -10px;
}

.crossIcon {
    cursor: pointer;
    color: #FFFFFF !important;
    width: 16px !important;
    height: 16px !important;
    background: no-repeat url("x.png");
}