@import "src/constants";

.filterContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background-color: $commonDark;
    max-height: 300px;
    padding-left: 5px;
    padding-bottom: 5px;
    width: 100vw;

    @media (max-width: 500px) {
        overflow-y: auto;
    }

    &Item {
        margin: 10px 10px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        &SubRow {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            color: rgb(128, 128, 139);

            &Item {
                margin: 0 0 0 0;
            }
            &Item:last-child {
                margin: 0 0 0 5px;
            }
        }

        &SubRowCenter {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: flex-start;
            color: rgb(128, 128, 139);

            &Item {
                margin: 0 5px 5px 0;
            }
        }
    }
}

.filterSelect {
    width: 180px;
    height: 41px !important;
    border-radius: 5px;
}

.filterInput {
    width: 100%;
    min-height: 37.99px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 16px;
}

.filterInputContainer {
    padding: 2px 3px;
}

.checkbox {
    color: $commonBlue !important;
}
.radio {
    height: 20px !important;
    width: 20px !important;
    color: rgb(128, 128, 139) !important;
}

.sort {
    padding: 0 !important;
    height: 38px !important;
    width: 40px !important;
}

.dateInput {
    width: 141px !important;
    background-color: #081023;
  //  height: 40px;
    color: #FFFFFF !important;
    margin: 0 !important;
    border-radius: 5px;
    padding-left: 2.5px !important;
}

.disabled {
    color: #fff !important;
    opacity: 0.5;
}

.filterList {
    max-width: 650px;
    min-width: 140px;
    height: 41px;
    max-height: 50px !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;
    z-index: 800 !important;
}

.dateIcon {
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    background: no-repeat url("calendar.svg");
}


.sortDesIcon {
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    background: no-repeat url("sortDesk.png");
}

.sortAskIcon {
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    background: no-repeat url("sortAsk.png");
}

.searchIcon {
    color: #FFFFFF;
    width: 24px;
    height: 24px;
    background: no-repeat url("search.png");
}

.sortBackground {
    background-color: $commonDark !important;
}

.searchButton {
    width: 100px;
    height: 41px !important;
    background-color: $commonBlue !important;
}

.searchField {
    border: none !important;
}

.selectDisabled {
    font-size: 16px;
    opacity: 0.5;
}

.inputDisabled {
    >span {
        color: #FFFFFF !important;
    }
    div {
        color: #FFFFFF !important;
    }
}

.inputBorderRadius {
    >span {
        border-radius: 5px;
    }
    div {
        border-radius: 5px;
    }
}

.dateInputDisabled,{
    opacity: 0.5;
    >span {
        color: #fff !important;
    }
    >div {
        color: #FFFFFF !important;
    }
}

.selectOpacity {
    opacity: 0.5;
}
