@import "src/constants";

.popupBack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.4);

    .posTagTitle {
        font-family: Open Sans, sans-serif;
        font-size: 24px !important;
        font-weight: 800 !important;
        letter-spacing: 0px;
        text-align: left;
        color: #707070;
    }

    .popupContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        left: calc(50% - 250px);
        width: 500px;
        max-width: calc(100% - 20px);
        background-color: white;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        gap: 30px;
        .editorContainer {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin: 8px;
        }

        .buttonContainer {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 5px;
            width: 80%;
            text-transform: none;
            color: black;

            .buttonAction {
                width: 105px;
                cursor: pointer;
                padding: 0 20px;
                height: 40px;
                background-color: $commonBlue;
                border-radius: 8px;
                border-width: 0;
                border-color: transparent;
                text-align: center;
                line-height: 40px;
                color: white;
            }

            .buttonCancel {
                cursor: pointer;
                width: 105px;
                height: 40px;
                background-color: transparent;
                line-height: 40px;
                border-color: $commonBlue;
                border-width: 1px;
                border-radius: 8px;
                text-align: center;
                color: $commonBlue;
                margin-left: 16px;
            }

            .buttonAction:focus,
            .buttonCancel:focus {
                outline: none;
            }
        }
    }
}

.disabled {
    opacity: 0.5;
}