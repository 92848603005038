@import "src/constants";

.container {
    display: flex;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: 100%;
    position: relative;

    span{
        color: #FFFFFF !important;
    }

    .loaderContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(85, 169, 255, 0.3);
        z-index: 99999;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .usersContainer {
        display: block;
        position: relative;
        width: calc(100% - 115px);
        height: calc(100% - 50px);
        overflow-y: auto;
        padding: 5px 10px;
    }

    .backLink {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50px;
        left: 59px;
        width: 250px;
        color: $commonBlue;
        text-decoration: none;
    }

    .buttonAdd {
        margin-bottom: 38px;
        margin-top: 38px;
        background-color: $commonBlue !important;
    }
}
