@import "src/constants";

.entriesRow {
    display: flex;
    padding: 0;
    margin: 25px 0 15px 0;

    .manualLanguageContainer {
        width: calc(12.388% - 13px);
    }

    .autocompleteLoader {
        position: absolute;
        left: 8px;
        top: -15px;
        z-index: 0;
    }

    .lemmaNotify {
        position: absolute;
        right: 6px;
        top: -23px;
        color: orange;
        cursor: pointer;
        z-index: 0;

        > svg {
            width: 20px;
            height: 20px;
        }
    }

    .synonymNotify {
        position: absolute;
        right: 57px;
        top: -19px;
        color: orange;
        cursor: pointer;
        z-index: 0;

        > svg {
            width: 20px;
            height: 20px;
        }
    }

    .lemmaContainer,
    .spellingContainer {
        position: relative;
    }

    .lemmaContainer {
        position: relative;
        height: 43px;

        .textEditButton {
            position: absolute;
            z-index: 0;
            top: -30px;
            right: 57px;
            zoom: 0.8;
            color: #FFFFFF;
        }

        .textEditButton:hover {
            opacity: 0.8;
        }
    }

    .synonymContainer {
        height: 43px;
        position: relative;
        border: none;
        border-color: transparent !important;

        .synonymItSelfLabel {
            display: flex;
            align-items: center;
            position: absolute;
            right: 5px;
            bottom: -25px;
            z-index: 0;
            cursor: pointer;

            .synonymItSelf {
                background-color: $commonDark;
                width: 13px !important;
                height: 13px !important;
                color: #FFFFFF;
            }

            .disabled {
                opacity: 0.5;
            }

            .checked {
                color: $commonLightBlue;
                background-color: #ffffff;
            }

            .label {
                margin-left: 5px;
                color: #FFFFFF;
            }
        }
    }

    .simpleDataContainer {
        display: flex;
        justify-content: center;
        margin-top: 11px;
        min-height: 50px;
        word-break: break-all;
        color: #FFFFFF;
    }

    > div {
        display: flex;
        align-items: flex-start;
        padding-left: 5px;
        padding-right: 5px;
        font-weight: normal;
        font-size: 14px;
        text-align: center;

        .languageListCont {
            display: flex;
            align-items: center;
            width: 100%;

            .languageListCheckBox {
                width: 13px !important;
                height: 13px !important;
                color: #FFFFFF;
                background-color: $commonDark;
                margin-right: 5px;
            }

            .checked {
                background-color: #ffffff;
                color: $commonLightBlue;
            }

            .languageList {
                width: calc(100% - 22px);
                min-height: 37.99px;
                background-color: $commonDark;
                color: #FFFFFF;
                padding: 2px 3px 2px 10px;
                text-align: left;
                border-radius: 5px;
            }

            .languageList:before {
                content: none !important;
            }
        }

        .posList,
        .netList {
            flex-grow: 1;
            min-height: 37.99px;
            padding: 2px 3px 2px 10px;
            text-align: left;
            border-radius: 5px;
        }

        .posListDisabled,
        .netListDisabled {
            opacity: 0.5;
            >span {
                font-family: "Open Sans", "SansSerif", serif !important;
            }
            div {
                color: #FFFFFF !important;
                font-family: "Open Sans", "SansSerif", serif !important;
            }
        }

        .posList:before,
        .netList:before {
            content: none !important;
        }

        .posList:disabled,
        .netList:disabled {
        }

        .lemmaList,
        .synonymList {
            width: 100%;
            background-color: $commonDark !important;

            input {
                color: #FFFFFF !important;
                max-width: 200px;
                background-color: $commonDark !important;
            }
        }

        .lemmaListWithoutBorder,
        .synonymListWithoutBorder {
            width: 100%;
            background-color: $commonDark !important;
            border: none !important;
            box-shadow: none !important;

            input {
                border: none !important;
                box-shadow: none !important;
                color: #FFFFFF !important;
                max-width: 200px;
                background-color: $commonDark !important;
            }
        }
    }
}

.entryNetListBacklog {
    width: 8.98% !important;
}

.lemmaListDisabled {
    opacity: 0.5;
}

.entriesRow:last-child {
    border: none;
}

.autocompleteOption {
    display: flex;
    justify-content: flex-start;
    font-size: 15px !important;
    text-align: left;
    padding: 2px;

    .autocompleteOptionSubElem {
        margin-left: 5px;
        color: #707070 !important;
        font-size: 15px !important;
        font-style: italic;
        font-family: "Open Sans", "SansSerif", serif;
    }
}

.selectDisabled {
    background-color: #434446 !important;
}

.inputDisabled {
    >span {
        font-family: "Open Sans", "SansSerif", serif !important;
        color: #fff !important;
        background-color: #434446 !important;
    }
    div {
        background-color: #434446 !important;
        color: #FFFFFF !important;
        font-family: "Open Sans", "SansSerif", serif !important;
    }
}

.iconContainer {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2E415B;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 5px;
}

.iconsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.editIcon {
    cursor: pointer;
    color: #FFFFFF !important;
    width: 16px !important;
    height: 16px !important;
    background: no-repeat url("pencil.png");
}

.deleteIcon {
    cursor: pointer;
    color: #FFFFFF !important;
    width: 16px !important;
    height: 16px !important;
    background: no-repeat url("trash.png");
}

.notifyStatusError {
    border: #E26655 solid 1px !important;
    border-radius: 8px;
    padding: 1px;
    background-color: $commonDark !important;
}

.notifyStatusSelected {
    border: $commonLightBlue solid 1px !important;
    border-radius: 8px;
    padding: 1px;
    background-color: $commonDark !important;
}

.notifyStatusError:active {
    border: $commonBlue solid 1px !important;
    border-radius: 8px;
    background-color: $commonDark !important;
}

.notifyStatusWarning {
    border: #FFE097 solid 1px !important;
    border-radius: 8px;
    padding: 1px;
    background-color: $commonDark !important;
}

.notifyStatusNormal {
    border: none;
    background-color: #7d2f2f !important;
}

.notifyStatusWarning:active {
    border: $commonBlue solid 1px !important;
    border-radius: 8px;
    padding: 1px;
    background-color: $commonDark !important;
}
