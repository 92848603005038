@import "src/constants";

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $commonDark;
}

.buttonsManualListPadding {
  padding: 10px 0 10px 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;

  .goToPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .goToPageButton {
      cursor: pointer;
      margin: 0 5px 0 5px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $commonBlue;
      color: $commonBlue;
    }

    .inputField {
      max-width: 80px;
      margin-left: 15px;
    }
  }
}

.pagination {
  display: flex;
  align-items: start;
  justify-content: center;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  margin: 0 5px 0 5px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  display: flex;
  align-items: start;
  justify-content: center;
}

.paginationLink {
  display: flex;
  align-items: start;
  justify-content: center;
  font-weight: bold;
}

.paginationLinkActive a {
  display: flex;
  align-items: start;
  justify-content: center;
  color: #fff;
  border-color: $commonLightBlue !important;
  background-color: $commonLightBlue;
}

.paginationLinkDisabled a {
  display: flex;
  align-items: start;
  justify-content: center;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
  cursor: default;
}

.loaderContainer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(85, 169, 255, 0.3);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editorContainerManualHeight {
  height: 100% !important;
}

.editorContainer {
  display: flex;
  flex-direction: row;
  width: calc(100% - 30px);
  padding: 5px 10px;

  .entriesContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    height: calc(100%);

    .entriesHeader {
      display: flex;
      padding: 0;
      height: 67px;
      align-items: center;
      background-color: $commonBlack;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      border-bottom: #FFFFFF solid 1px;

      .selectAllCheckbox {
        width: 17px !important;
        height: 17px !important;
        color: #FFFFFF;
        background-color: $commonDark;
        position: absolute;
        padding: 2px 1px 0 0px;
        margin-left: 7px;
      }

      .checked {
        color: $commonLightBlue;
        background-color: #FFFFFF;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 67px;
        color: #FFFFFF;
        padding: 5px 0;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .entriesHeaderManual {
      > div {
        width: 22.15%;
        font-size: 18px;
      }
    }

    .entriesHeaderBacklog {
      > div {
        width: 17%;
        font-size: 18px;
      }
    }

    .entriesHeaderBacklogApp {
      > div {
        width: 14%;
        font-size: 18px;
      }
    }

    .entryRowsListContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      overflow-y: auto;

      .entriesRowsFullContainer {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        min-height: 300px;

        .entriesRowsContainer {
          overflow-y: auto;
          flex-grow: 1;
          background-color: $commonBlack;
          border-bottom-right-radius: 16px;
          border-bottom-left-radius: 16px;
          scrollbar-color: #44566c #bdbdbd;
          scrollbar-width: 2px !important;
        }

        .entriesRowsContainer::-webkit-scrollbar-button {
          display: none;
        }

        .entriesRowsContainer::-webkit-scrollbar {
          width: 7px;
          display: block;
          position: absolute;
          left: 0;
          height: 0;
        }

        .entriesRowsContainer::-webkit-scrollbar-track {
          width: 2px;
          background: #a5a5a5;
          border: 1px solid transparent;
          background-clip: content-box;
        }

        .entriesRowsContainer::-webkit-scrollbar-thumb {
          display: block;
          width: 1px !important;
          height: 40px !important;
          background-color: #44566c;
          border-radius: 5px;
        }

      }
    }
  }
}

.buttonDisabled {
  opacity: 0.5 !important;
}

.uploadCheckButton {
  color: #FFFFFF !important;
  margin-left: 10px !important;
  width: 285px;
  background-color: #0C4DB4 !important;
  height: 51px;
}

.backlogSaveButton {
  color: #0C4DB4 !important;
  width: 185px;
  background-color: #D2D4D8 !important;
  margin-left: 10px !important;
  height: 51px;
}

.deleteSelectedButton {
  color: #FFFFFF !important;
  margin-left: 10px !important;
  width: 205px;
  background-color: #E26655 !important;
  height: 51px;
}

.addButtonContainer {
  position: absolute;
  left: 10px;
  top: 45px;

  .addButton {
    color: $commonBlue !important;
    font-size: 34px !important;
    cursor: pointer;
  }
}

.backLink {
  display: none;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 250px;
  color: $commonBlue !important;
  text-decoration: none;
}

.trashIcon {
  width: 16px !important;
  height: 16px !important;
  margin-left: 4px;
  background: no-repeat url("trash.png");
}

.saveIcon {
  width: 16px !important;
  height: 16px !important;
  margin-left: 4px;
  background: no-repeat url("save.png");
}

.checkIcon {
  width: 16px !important;
  height: 16px !important;
  margin-left: 4px;
  background: no-repeat url("check.png");
}
