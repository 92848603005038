.container {
  width: 100%;
}

.supportContainer {
  display: none;
  position: absolute;
  z-index: 888;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 745px;
  height: 835px;
  max-height: 80%;
  padding: 40px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  background-color: white;

  .supportInfoList {
    list-style-type: circle;
    list-style-position: inside;
  }
}

.supportSubContainer {
  overflow-y: auto;
  max-height: 100%;
  >img {
    max-width: 100%;
  }
}

.supportContainerShow {
  display: block;
  opacity: 1;
}

.supportCloseButton {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}