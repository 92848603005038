@import "src/constants";

.bottomPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 60px);
  background-color: $commonBlack;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  padding-top: 25px;

  .deleteButtonContainer {
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: solid 2px $commonGrey;

    .button {
      width: 100%;
      height: 41px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .buttonRow {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $commonBlue;
        padding: 8px 10px;
        border-radius: 8px;
        color: #ffffff;
        cursor: pointer;

        .deleteIcon {
          width: 24px;
          height: 24px;
          color: white;
          cursor: pointer;
          margin-right: 8px;
          background: no-repeat url("trash.png");
        }
      }
    }
  }

  .topBottomPart {
    padding: 12px 35px 0 35px;
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
    flex-direction: column;
    color: #ece9ff;
    overflow-y: auto;
    overflow-x: auto;

    .detailedNet {
      width: 100%;

      .infoLocationMargin {
        margin-top: 0 !important;
        margin-left: 12px;
        overflow: auto;
      }

      .infoLocationMargin::-webkit-scrollbar-button {
        display: none;
      }

      .infoLocationMargin::-webkit-scrollbar {
        width: 7px;
        display: block;
        position: absolute;
        left: 0;
        height: 0;
      }

      .infoLocationMargin::-webkit-scrollbar-track {
        width: 2px;
        background: #a5a5a5;
        border: 1px solid transparent;
        background-clip: content-box;
      }

      .infoLocationMargin::-webkit-scrollbar-thumb {
        display: block;
        width: 1px !important;
        height: 40px !important;
        background-color: #44566c;
        border-radius: 5px;
      }

      .locationLeftItemWidth {
        min-width: 25px !important;
      }

      .info {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #ece9ff !important;

        &Row {
          width: 100%;
          display: flex;
          font-size: 16px;
          margin-top: 5px;

          &LeftItem {
            width: 50%;
            min-width: 50px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-right: 15px;
            font-size: 15px;
            font-family: "Open Sans", "SansSerif", sans-serif;
            color: #707070;
          }

          &LeftItemWithoutWidth {
            min-width: 25px !important;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-right: 15px;
            font-weight: bold;
            font-size: 15px;
            font-family: "Open Sans", "SansSerif", sans-serif;
            color: #ffffff;
          }

          &RightItem {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-size: 15px;
            font-family: "Open Sans", "SansSerif", sans-serif;
            align-items: center;
            color: #ffffff;
          }

          &RightItemWithoutWidth {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-size: 15px;
            font-family: "Open Sans", "SansSerif", sans-serif;
            color: #ece9ff;
          }
        }
      }

      .locationInfo {
      }
    }
  }

  .topBottomPart::-webkit-scrollbar-button {
    display: none;
  }

  .topBottomPart::-webkit-scrollbar {
    width: 7px;
    display: block;
    position: absolute;
    left: 0;
    height: 0;
  }

  .topBottomPart::-webkit-scrollbar-track {
    width: 2px;
    background: #a5a5a5;
    border: 1px solid transparent;
    background-clip: content-box;
  }

  .topBottomPart::-webkit-scrollbar-thumb {
    display: block;
    width: 1px !important;
    height: 40px !important;
    background-color: #44566c;
    border-radius: 5px;
  }

}

.locationIcon {
  color: #ffffff !important;
  width: 24px;
  height: 24px;
  background: no-repeat url("location.png");
}

.header {
  @extend %header;
}

.subElemSpanGrey {
  @extend %subElemSpanGrey;
}

.subElemNet {
  @extend %subElemNet;
  margin-bottom: 10px;
}

.detailedNetHeader {
  @extend %detailedNetHeader;
}

.detailedNetHeaderEmpty {
  @extend %detailedNetHeaderEmpty;
}
